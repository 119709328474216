(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/handlebar-helpers/assets/javascripts/actions-helper.js') >= 0) return;  svs.modules.push('/components/banner_common/handlebar-helpers/assets/javascripts/actions-helper.js');

'use strict';

const isServer = typeof exports === 'object';
let formatCurrency;
let gameTypes;
if (isServer) {
  var _trinidad$components$;
  const trinidad = require('trinidad-core').core;
  formatCurrency = (_trinidad$components$ = trinidad.components.require('utils', 'format').api) === null || _trinidad$components$ === void 0 ? void 0 : _trinidad$components$.Currency;
  gameTypes = trinidad.components.require('poker', 'common').constants.gameTypes;
}
function formatCurrencyValues(gameType, exchangeRate) {
  const calculatedValue = gameTypes[gameType] * exchangeRate;
  return {
    value: calculatedValue,
    maxWinValue: calculatedValue * 1000
  };
}
function registerHelpers(hbs) {
  hbs.registerHelper('dynamicActionTemplate', (actionType, actionConfig, clickTrackingString) => {
    var _actionType;
    let template;
    let compiled;
    if (actionType === 'no-action') {
      return;
    }
    actionType = (_actionType = actionType) === null || _actionType === void 0 ? void 0 : _actionType.replace(/-/g, '_');
    if (isServer) {
      const hbs = global.internalInstances.get('hbs');
      template = actionType && hbs.cache["components-banner_actions-".concat(actionType, "-").concat(actionType)];
    } else {
      template = actionType && svs.banner_actions[actionType].templates[actionType];
    }
    if (typeof template === 'function') {
      compiled = template;
    } else if (typeof template === 'string') {
      compiled = hbs.partials[template];
    }
    if (!compiled) {
      return;
    }
    return new hbs.SafeString(compiled({
      action: actionConfig,
      clickTrackingString,
      formTrackingString: clickTrackingString
    }));
  });
  hbs.registerHelper('formatGameType', (gameType, valueType) => {
    if (isServer) {
      var _trinidad$config$opti;
      const trinidad = require('trinidad-core').core;
      const exchangeRate = (_trinidad$config$opti = trinidad.config.options) === null || _trinidad$config$opti === void 0 || (_trinidad$config$opti = _trinidad$config$opti.cms) === null || _trinidad$config$opti === void 0 ? void 0 : _trinidad$config$opti.exchangeRate;
      if (!gameType || !valueType) {
        return;
      }
      if (valueType === 'getValueSEK') {
        return formatCurrency(formatCurrencyValues(gameType, exchangeRate).value);
      }
      if (valueType === 'getMaxValueSEK') {
        return formatCurrency(formatCurrencyValues(gameType, exchangeRate).maxWinValue);
      }
    }
  });
}
if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);