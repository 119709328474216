(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/handlebar-helpers/assets/javascripts/banner-helpers.js') >= 0) return;  svs.modules.push('/components/banner_common/handlebar-helpers/assets/javascripts/banner-helpers.js');

'use strict';

function registerHelpers(hbs) {
  hbs.registerHelper('getProductMetaData', (product, prop) => {
    switch (product) {
      case 'bingo':
        return {
          headerBg: 'bg-red-100',
          bodyBg: 'bg-red-100',
          text: 'fc-navy',
          emblemOutline: false
        }[prop];
      case 'bomben':
        return {
          headerBg: 'bg-red-100',
          bodyBg: 'bg-red-100',
          text: 'fc-navy',
          emblemOutline: false
        }[prop];
      default:
        return {
          headerBg: 'bg-red',
          bodyBg: 'bg-red-100',
          text: 'fc-navy',
          ribbon: 'default',
          emblemOutline: true
        }[prop];
    }
  });

  hbs.registerHelper('getRel', action => {
    if ((action === null || action === void 0 ? void 0 : action.linkType) === 'external') {
      if (!action.hasOwnProperty('linkNofollow')) {
        return 'nofollow noopener noreferrer';
      }
      return "".concat(action.linkNofollow ? 'nofollow' : '', " noopener noreferrer");
    }
  });
  hbs.registerHelper('isEven', i => {
    if (i % 2 !== 0) {
      return true;
    }
  });
  hbs.registerHelper('isOdd', i => {
    if (i % 2 === 0) {
      return true;
    }
  });

  hbs.registerHelper('setDefault', function (defaultParam) {
    let param = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    return param !== undefined ? param : defaultParam;
  });

  hbs.registerHelper('limitContent', (content, limit) => {
    if ((content === null || content === void 0 ? void 0 : content.length) > limit) {
      return "".concat(content.slice(0, limit), "<span class=\"ellipsis\">...<span>");
    }
    return content;
  });

  hbs.registerHelper('renderCrmAttributes', crm => {
    if (!crm) {
      return;
    }
    let attributes = '';
    const crmKeys = crm && Object.keys(crm);
    if (crmKeys) {
      crmKeys.forEach(key => {
        attributes += "data-crm-".concat(key, "=\"").concat(crm[key], "\" ");
      });
    }
    return new hbs.SafeString(attributes);
  });
  hbs.registerHelper('renderCmsList', listItems => {
    if (!listItems || !listItems.items) {
      return '';
    }
    const isUnordered = listItems.listType === 'unordered';
    const tag = isUnordered ? 'ul' : 'ol';
    let result = "<".concat(tag, ">");
    listItems.items.forEach(item => {
      result += "<li>".concat(item.text, "</li>");
    });
    result += "</".concat(tag, ">");
    return new hbs.SafeString(result);
  });
  hbs.registerHelper('translateProduct', product => {
    const mapping = {
      '': '',
      default: 'Svenska Spel',
      stryktipset: 'Stryktipset',
      europatipset: 'Europatipset',
      topptipset: 'Topptipset',
      maltipset: 'Måltipset',
      oddset: 'Oddset',
      challenge: 'Challenge',
      powerplay: 'Powerplay',
      bomben: 'Bomben',
      matchen: 'Matchen',
      bingo: 'Bingo',
      casino: 'Casino',
      emtipset: 'Em-tipset',
      mixen: 'Mixen',
      poker: 'Poker',
      vmtipset: 'Vm-tipset',
      fulltraff: 'Fullträff',
      hastar: 'Hästar'
    };
    return mapping[product] || product;
  });
}
if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);